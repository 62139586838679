@import 'utils';

.body-text {
    font-family: 'Montserrat', 'Lucida Bright', 'Montserrat', sans-serif;
    font-size: 1em;
    line-height: 26px;
    @media (max-width: 610px) {
        font-size: 0.75em;
        line-height: 30px;
    }
    @media (max-width: 390px) {
        font-size: .7em;
    }
}
.body-highlight {
    font-family: 'Montserrat', 'Lucida Bright', 'Montserrat', sans-serif;
    font-size: 1.5em;
    @media (max-width: 610px) {
        font-size: 1em;
    }
    @media (max-width: 390px) {
        font-size: .75em;
    }
}

.header-text {
    font-family: 'Montserrat','Trebuchet MS', 'Lucida Bright', 'Inter', sans-serif;
    font-size: 2.5em;
    text-transform: uppercase;
    @media (max-width: 410px) {
        font-size: 2em;
    }
}
.title{
    @media (max-width: 1088px) {
        font-size: 1.7em !important;
    }
    @media (max-width: 700px) {
        font-size: large !important;
    }
}

.body{
    @media (max-width: 1088px) {
        font-size: small !important;
    }
}

.body-hight{
    @media (max-width: 1088px) {
        font-size: 1.7em !important;
    }
    @media (max-width: 700px) {
        font-size: large !important;
    }
    @media (max-width: 500px) {
        font-size: small !important;
    }
}

.fade-in {
    -webkit-animation-name: fadeInOpacity;
    animation-name: fadeInOpacity;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.left-to-right {
    animation: sortRight 700ms ease-in-out forwards;
}

@keyframes fadeInOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes sortRight {
    from {
        transform: translateX(999px);
    }
    to {
        transform: translateX(0px);
    }
}


